import React, { Component } from "react";
import PropTypes from "prop-types";
import Reaptcha from "reaptcha";
 
class CustomRecaptchaLogin extends Component {
  static propTypes = {
    wrapperClassName: PropTypes.string,
    onTokenChange: PropTypes.func,
  };
 
  static defaultProps = {
    wrapperClassName: "",
    onTokenChange: () => {},
  };
 
  constructor() {
    super();
    this.state = {
      validated: "",
    };
  }
 
  _onChangeHandler = (token) => {
    this.setState({ validated: token });
    this.props.onTokenChange(token);
  };
 
  render() {
    const { wrapperClassName } = this.props;
 
    return (
      <div className="row">
        <div className={`recaptcha-wrapper ${wrapperClassName}`}>
          <Reaptcha
            sitekey="6LdSa48nAAAAABlClbYXuXpZuGnRqDsWA6CUfChV"
            onVerify={(token) => this._onChangeHandler(token)}
            onExpire={() => this._onChangeHandler("")}
          />
          <input
            type="hidden"
            name="google-recaptcha"
            className="hide"
            value={this.state.validated}
            required
          />
        </div>
      </div>
    );
  }
}
 
export default CustomRecaptchaLogin;